import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import thankYouMessage from "../../assets/order-confirmation.svg";
import paymentFaild from "../../assets/order-faild.svg";
import loaderImage from "../../assets/loaderIcon.png";
import SuperMaster from "../../layouts/SuperMaster";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updatePaymentStatus } from "../../https/ordersRequests";
import { toast } from "react-toastify";
import {
  errorRequestHandel,
  getCurrencyMultiplier,
} from "../../utils/helperFile";
import {
  trackPurchase,
  ttqTrackCompletePayment,
} from "../../utils/analyticsEvents";
import { useSelector } from "react-redux";

const OrderConfirmationMobileApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const isPayment = queryParams.get("isPayment");
  const _identifier = params?.id?.trim();
  const hasCalledUpdatePaymentStatusAPI = useRef(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isPayment = queryParams.get("isPayment");
    const _identifier = queryParams.get("identifier").trim();

    if (isPayment && !hasCalledUpdatePaymentStatusAPI.current) {
      hasCalledUpdatePaymentStatusAPI.current = true;
      _updatePaymentStatus(_identifier);
      return; // Exit early from the effect
    } else {
      setPaymentSuccess(true);
      setLoading(false);
    }
  }, []);

  const _updatePaymentStatus = async (_identifier) => {
    setLoading(true);
    try {
      const response = await updatePaymentStatus(_identifier);
      if (response.status === 200) {
        if (response?.data?.paymentStatus) {
          //   toast.success("Order placed");
          setPaymentSuccess(true);
          setLoading(false);
          console.log(
            JSON.stringify({ status: "success", identifier: _identifier })
          ); // Log success status with identifier
        } else {
          // toast.error(response?.data?.message);
          setPaymentSuccess(false);
          setLoading(false);
          console.log(
            JSON.stringify({ status: "failure", identifier: _identifier })
          ); // Log failure status with identifier
        }
      }
    } catch (error) {
      errorRequestHandel({ error: error });
      setLoading(false);
    }
  };

  const handleViewOrderDetails = () => {
    // Navigate to the order details screen
    // navigate(`/orders/details/${_identifier}`);
    navigate(`/orders/details/${_identifier}`, {
      state: { fromOrderConfirmation: true },
    });
  };

  return (
    <div>
      {loading ? (
        <div className="animated__loader_container">
          <div className="animated__loader">
            <img src={loaderImage} />
          </div>
        </div>
      ) : (
        <div className="order__confirmation__container">
          {paymentSuccess ? (
            <div className="order__confirmation__body">
              <div className="order__confirmation__message">
                <img src={thankYouMessage} />
                <p>For shopping with us</p>
              </div>
              <div className="order__confirmation__description">
                <span>Your order has been confirmed.</span>
                <p>You will receive a confirmation email soon.</p>
              </div>
            </div>
          ) : (
            <div className="order__confirmation__body">
              <div className="order__confirmation__message">
                <img src={paymentFaild} />
              </div>
              <div className="order__confirmation__description">
                <span>Payment Failed.</span>
                <p>Please retry payment to compelete the order.</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderConfirmationMobileApp;
