import { CHANGE_COUNTRY } from "../../actionTypes/Index";
import {
  IS_FILTER_MODAL,
  IS_FORGOT_PASSWORD_MODAL,
  IS_LOGIN_MODAL,
  IS_REGISTRATION_MODAL,
  IS_SIDE_MENU_MODAL,
  TOGGLE_OVERLAY,
} from "../actions/settingsAction";

const initialState = {
  selectedCountry: {},
  overlayEnabled: false,
  isLoginModal: false,
  isSideMenuModal: false,
  isForgotPasswordModal: false,
  isRegistrationModal: false,
  isFilterModal: false,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_COUNTRY:
      localStorage.setItem("selectedCountry", action.payload);
      return {
        ...state,
        selectedCountry: action.payload,
      };
      break;
    case TOGGLE_OVERLAY:
      return {
        ...state,
        overlayEnabled: action.payload,
      };
      break;
    case IS_LOGIN_MODAL:
      return {
        ...state,
        isLoginModal: action.payload,
      };
      break;
    case IS_SIDE_MENU_MODAL:
      return {
        ...state,
        isSideMenuModal: action.payload,
      };
      break;
    case IS_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        isForgotPasswordModal: action.payload,
      };
      break;
    case IS_REGISTRATION_MODAL:
      return {
        ...state,
        isRegistrationModal: action.payload,
      };
      break;
    case IS_FILTER_MODAL:
      return {
        ...state,
        isFilterModal: action.payload,
      };
      break;
    default:
      return state;
  }
}
