import React, { useEffect, useState } from "react";
import urgencyIocn from "../../assets/icons/urgencyIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  _decrementTimer,
  _resetTimer,
  _startTimer,
  _stopTimer,
} from "../../redux/actions/urgencyActions";

const SenseOfUrgency = ({ isCart }) => {
  const dispatch = useDispatch();
  const { timerRunning, secondsRemaining } = useSelector(
    (state) => state._timer
  );
  const cartItems = useSelector((state) => state._products.cartItems);

  useEffect(() => {
    if (cartItems.length > 0 && !timerRunning) {
      dispatch(_startTimer());
    }
  }, [dispatch, cartItems, timerRunning]);

  useEffect(() => {
    let countdown;

    if (timerRunning && secondsRemaining > 0) {
      countdown = setInterval(() => {
        dispatch(_decrementTimer()); // Dispatch action to decrement timer
      }, 1000);
    } else if (secondsRemaining === 0) {
      dispatch(_stopTimer());
    }

    return () => clearInterval(countdown); // Cleanup interval
  }, [dispatch, timerRunning, secondsRemaining]);

  if (cartItems.length === 0) {
    return null; // Hide the component if there are no items in the cart
  }
  const minutes = String(Math.floor(secondsRemaining / 60)).padStart(2, "0");
  const seconds = String(secondsRemaining % 60).padStart(2, "0");
  return (
    <div className="sense_urgency__contaienr">
      <div
        className={
          isCart ? "sense_urgency_for_cart__body" : "sense_urgency__body"
        }
      >
        <div className="sense_urdency_icon__section">
          <img src={urgencyIocn} alt="Hurry up" />
        </div>
        <div className="sense_urdency_description">
          {timerRunning > 0 ? (
            <>
              <span>Hurry! Limited availability! Checkout in</span>

              <div className="timer">
                <div className="time-box">
                  <div className="time-number">{minutes}</div>
                  {/* <div className="time-label">M</div> */}
                </div>
                <div className="time-box">
                  <div className="time-number">{seconds}</div>
                  {/* <div className="time-label">S</div> */}
                </div>
              </div>
            </>
          ) : (
            <span>
              You're out of time ! Checkout now to avoid losing your order !
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SenseOfUrgency;
