import React from "react";
import "./style.css";

const OutOfStock = () => {
  return (
    <div className="out_of_stock__container">
      <p>Out of Stock</p>
    </div>
  );
};

export default OutOfStock;
