import React from "react";
import fireIcon from "../../assets/icons/fireIcon.svg";
import { formatNumberWithK } from "../../utils/helperFile";

const ItemsSoldOutwithDuration = ({ itemCount = 0, duration }) => {
  return (
    <div className="item_sold__container">
      <div className="item_sold__body">
        <div className="item_sold__icon_section">
          <img src={fireIcon} alt="sold items" />
        </div>
        <div className="item_sold__counter_section">
          <span>
            <small>{formatNumberWithK(itemCount)}</small> people bought this
            product in last <small>{duration} hours</small>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ItemsSoldOutwithDuration;
