export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const TOGGLE_OVERLAY = "TOGGLE_OVERLAY";
export const IS_LOGIN_MODAL = "IS_LOGIN_MODAL";
export const IS_SIDE_MENU_MODAL = "IS_SIDE_MENU_MODAL";
export const IS_FORGOT_PASSWORD_MODAL = "IS_FORGOT_PASSWORD_MODAL";
export const IS_REGISTRATION_MODAL = "IS_REGISTRATION_MODAL";
export const IS_FILTER_MODAL = "IS_FILTER_MODAL";

export const _setCountry = (country) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_COUNTRY,
      payload: country,
    });
  };
};

export const _toggleOverylay = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OVERLAY,
      payload: toggle,
    });
  };
};
export const _toggleLoginModal = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOGIN_MODAL,
      payload: toggle,
    });
  };
};
export const _toggleSideMenuModal = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: IS_SIDE_MENU_MODAL,
      payload: toggle,
    });
  };
};
export const _toggleForgotPasswordModal = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: IS_FORGOT_PASSWORD_MODAL,
      payload: toggle,
    });
  };
};
export const _toggleRegistrationModal = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: IS_REGISTRATION_MODAL,
      payload: toggle,
    });
  };
};
export const _toggleFilterModal = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: IS_FILTER_MODAL,
      payload: toggle,
    });
  };
};
