import React from "react";
import { formatNumberWithK } from "../../utils/helperFile";
import viewEyeIcon from "../../assets/icons/viewEyeIcon.svg";

const SalesAndViewCounter = ({ count = 0 }) => {
  if (!count || count === 0) {
    return null;
  }
  return (
    <div className="sales_view__contaienr">
      <div className="sales_view__body">
        <div className="sales_view__icon_section">
          <img src={viewEyeIcon} alt="sale view" />
        </div>
        <div className="sales_view__counter_section">
          <span>
            <small>{formatNumberWithK(count)}</small> People are viewing this
            product right now
          </span>
        </div>
      </div>
    </div>
  );
};

export default SalesAndViewCounter;
