// actions/timerActions.js

export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";
export const RESET_TIMER = "RESET_TIMER";
export const DECREMENT_TIMER = "DECREMENT_TIMER";
export const SET_SECONDS_REMAINING = "SET_SECONDS_REMAINING";

export const _startTimer = () => ({ type: START_TIMER });
export const _stopTimer = () => ({ type: STOP_TIMER });
export const _resetTimer = () => ({ type: RESET_TIMER });
export const _decrementTimer = () => ({
  type: DECREMENT_TIMER,
});
export const setSecondsRemaining = (seconds) => {
  return {
    type: SET_SECONDS_REMAINING,
    payload: seconds,
  };
};
