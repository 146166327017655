import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _ToggleCartModal, _addItemToCart } from "../../redux/actions/product";
import { _toggleOverylay } from "../../redux/actions/settingsAction";
import { trackAddToCart, ttqtrackAddToCart } from "../../utils/analyticsEvents";
import { getCurrencyMultiplier } from "../../utils/helperFile";
import { useParams } from "react-router-dom";
import { calculateDiscountPercentage } from "../../helpers/Index";
import { addToCart } from "../../actions/Cart";

const ProductSizes = ({ product, isOnMobile }) => {
  const { id: categoryIndentifer } = useParams(); // 'category' will be 'CLEARANCE'

  const dispatch = useDispatch();

  const exchangeRates = useSelector((state) => state._general.exchangeRates);
  const selectedCountry = useSelector(
    (state) => state._settings.selectedCountry
  );
  const [rateMultiplier, setRateMultiplier] = useState(1);

  useEffect(() => {
    setRateMultiplier(
      getCurrencyMultiplier(exchangeRates, selectedCountry?.currency)
    );
  }, [selectedCountry, exchangeRates]);

  let _width = 100 / product.variants.length;
  const isAnyVariantsOnSale = product.variants.some(
    (variant) => variant.on_sale && variant.quantity > 0
  );

  const addToCartButtonAction = (variant) => {
    dispatch(_addItemToCart(product, variant));
    dispatch(_ToggleCartModal(true));
    dispatch(_toggleOverylay(true));
    trackAddToCart(product, variant, rateMultiplier, selectedCountry?.currency);
    ttqtrackAddToCart(product, variant);
    return;
  };
  return (
    <div
      className={isOnMobile ? "_clothSizes _clothSizesOnMobile" : "_clothSizes"}
    >
      {product.variants?.length > 0 &&
        product.variants?.map((variant, index) => {
          // if (!variant.on_sale && categoryIndentifer === "CLEARANCE") {
          //   return null;
          // }
          const isDisabled = variant.quantity === "0" || variant.quantity === 0;
          return (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                width: "25%",
              }}
              disabled={isDisabled}
              onClick={isDisabled ? null : () => addToCartButtonAction(variant)}
            >
              <span
                className={`primary ${isDisabled ? "crossed-out__line" : ""}`} // Apply crossed-out class if disabled
                disabled={isDisabled}
              >
                {variant.size}
              </span>
              {variant.on_sale ? (
                <div
                  className={`discount-tag ${isDisabled ? "disabled" : ""}`}
                  style={{
                    height: "20px",
                  }}
                >
                  {calculateDiscountPercentage(
                    variant.sale_price,
                    variant.regular_price,
                    true
                  )}
                  % OFF
                </div>
              ) : (
                <div
                  style={{
                    height: isAnyVariantsOnSale ? "20px" : "0",
                  }}
                ></div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ProductSizes;
