import React from 'react'
import Master from '../../layouts/Master';

function ForgotPassword() {
    return (
        <Master>
            ForgotPassword page
        </Master>
    )
}
export default ForgotPassword;
