// reducers/timerReducer.js

import moment from "moment/moment";
import {
  START_TIMER,
  STOP_TIMER,
  RESET_TIMER,
  DECREMENT_TIMER,
} from "../actions/urgencyActions";

const initialState = {
  timerRunning: false,
  secondsRemaining: 60 * 20, // Initial timer value
};

const timerReducer = (state = initialState, action) => {
  let cartSessionStartedTime = localStorage.getItem("cartSessionStartedTime");

  let secondsRemaining = 0;
  if (cartSessionStartedTime) {
    secondsRemaining = 1200 - moment().diff(cartSessionStartedTime, "seconds");
    secondsRemaining = secondsRemaining <= 0 ? 0 : secondsRemaining;
  }
  switch (action.type) {
    case START_TIMER: {
      if (
        !cartSessionStartedTime ||
        cartSessionStartedTime === undefined ||
        cartSessionStartedTime === ""
      ) {
        localStorage.setItem("cartSessionStartedTime", moment());
        return {
          ...state,
          timerRunning: true,
          secondsRemaining: state.secondsRemaining,
        };
      } else {
        return {
          ...state,
          timerRunning: true,
          secondsRemaining, // Ensure remaining time is not negative
        };
      }
    }

    case STOP_TIMER:
      return { ...state, timerRunning: false };
    case RESET_TIMER:
      localStorage.removeItem("cartSessionStartedTime");
      return {
        ...state,
        secondsRemaining: 60 * 20,
        timerRunning: false,
      }; // Reset timer to initial value
    case DECREMENT_TIMER:
      return {
        ...state,
        secondsRemaining, // Ensure remaining time is not negative
      };
    default:
      return state;
  }
};

export default timerReducer;
